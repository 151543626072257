.site__header {
    top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative; 
    z-index: 2000;
}

@media (min-width: 769px) {
    .site__header {
        display: none; /* Ocultar la navegación en pantallas grandes */
    }
}


.menu__button {
    color: #9AC31C;
}

.menu__button:focus,
.menu__button:hover {
    color: #F6E823;
    box-shadow: unset;
}


.nav_ac {
    padding: 5px 15px;
    margin: 0;
    border: unset;
    font-size: 1.25rem;
    font-family: Marcellus;
    line-height: 2;
    height: 50px;
    font-weight: bold;
    z-index: 1000;
}

.nav_ac:hover {
    color: white;
}

.br-top,
.br-bottom,
.br-right,
.br-left {
    position: fixed;
    z-index: 999999;
    background: var(--primary-color);
}

.br-top {
    top: 0;
    height: 10px;
    left: 0;
    width: 100%;
}

.br-bottom {
    bottom: 0;
    left: 0;
    height: 10px;
    width: 100%;
}

.br-right {
    width: 10px;
    right: 0;
    top: 0;
    height: 100%;
}

.br-left {
    width: 10px;
    left: 0;
    top: 0;
    height: 100%;
}

.cortina__wrapper-menu {
    position: relative;
    width: 100%;
    padding-top: 5em;
    padding-bottom: 3em;
    height: 100%;
    overflow-y: auto;
}

.site__navigation {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    visibility: hidden;
}

.menu__opend {
    visibility: visible !important;
}

.main__menu_ul,
.menu_right {
    opacity: 0;
    position: relative;
    transition: 0.5s;
    transition-delay: 0s;
    visibility: hidden;
    z-index: 100;
}

.menu_right {
    text-align: center;
}

.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
    opacity: 1;
    transition-delay: 0.6s;
    visibility: visible;
}

.site__navigation .main__menu_ul li {
    list-style: none;
    margin: 10px 0;
}

.site__navigation .main__menu_ul li a {
    color: var(--text-color);
    display: block;
    font-size: 2.5rem;
    text-decoration: none;
}

.bg__menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    transform: translateY(-100%);
    transition: 0.8s ease all;

    background-color: #0a0a0a;
    /* background: linear-gradient(to bottom, #F6E823, #9AC31C); */

}


.menu__opend .bg__menu {
    transform: translateY(0);
}

.menu__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
}

.the_menu {
    padding-top: 230px;
    padding-left: 0;
    padding-bottom: 20px;
}

@media (min-width: 992px) {
    .menu__container {
        margin-left: 33.3333%;
    }
    .the_menu {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
}

.the_menu .menu_item>a {
    color: rgb(255, 255, 255);
    line-height: 2;
    font-size: 2rem;
    padding: 4px 0px;
    text-decoration: none;
    font-family: 'Kanit-ExtraBold', sans-serif;
    text-transform: uppercase;
    margin-left: 30px;
}

.the_menu .menu_item>a:hover {
    color: var(--text-color-3);
}

@media (min-width: 768px) {
    .the_menu .menu_item>a {
        font-size: 4.8vw;
      
    }
}

.menu_footer {
    bottom: 0;
    color: rgb(255, 255, 255);
   
    font-size: 13px;
    font-family: 'Kanit-ExtraBold';
    background: linear-gradient(to bottom, #0a0a0a, #fffb283c);
    opacity: 0; /* Opacidad inicial */
    transition: opacity 0.8s ease; /* Transición suave de la opacidad */
}

.site__navigation.menu__opend .menu_footer {
    opacity: 1; /* Opacidad completa cuando el menú está abierto */
}

.menu_footer a {
    color: rgb(254, 0, 0);
    margin-right: 10px;
    text-decoration: none;
}











.burger-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 35px; /* Tamaño suficiente para las animaciones */
    height: 35px; /* Altura adecuada */
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    position: fixed;
    right: 30px!important;
    top: 23px;
    z-index: 1000;
}
  
  .burger-button:focus {
    outline: none;
  }
  
  .burger-button span {
    display: block;
    width: 100%;
    height: 1.1px; /* Altura estándar de las barras */
    background: #F6E823; /* Color original */
    border-radius: 10px;
    transition: all 0.4s linear;
    transform-origin: 0% 50%; /* Ajusta el origen de transformación al inicio y centro vertical de la barra */
}

.burger-button.open span {
    background: #F6E823; /* Cambia el color a negro cuando el menú está abierto */
}



.burger-button.open span:first-child {
    transform: rotate(43deg); /* Rotación de la primera barra */
}

.burger-button.open span:nth-child(2) {
    opacity: 0; /* Ocultar la barra del medio en el estado abierto */
}

.burger-button.open span:nth-child(3) {
    transform: rotate(-43deg); /* Rotación de la tercera barra */
}


  .site__navigation {
    height: 100%;
    right: 0; /* Cambia desde 'left: 0;' a 'right: 0;' para que despliegue desde la derecha */
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    visibility: hidden;
    transition: visibility 0.3s ease;
}

.menu__opend {
    visibility: visible !important;
    transform: translateX(0);
    overflow-y: auto; /* Asegura que el menú tenga scroll cuando está abierto */
}





.ovhidden {
    overflow: hidden;
  }


