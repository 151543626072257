body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0a0a0a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Estilos del scrollbar para Chrome, Edge y Safari */
::-webkit-scrollbar {
 
  width: 1px; /* Ancho del scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background-color: black; /* Fondo transparente */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #f6e823; /* Color de la barra */
  border-radius: 2px; /* Borde redondeado */

  
}


